
.menu-wrapper {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}

.menu-wrapper div:not(.menu-circle) {
    height: 40px;
    transition: max-height .3s ease-in-out;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;

}

.menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    outline: none;
    height: 100%;
    justify-content: center;
    padding: 3.2vh 0;
    max-width: calc(100vw / 6);
}

.menu-circle.center {
    /*height: 9;*/
    filter: unset;
}

.menu-label {
    /*background-color: rgba(0,0,0,0.42);*/
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 700;
    text-align: right;
    font-size: 1.2em;
    color: whitesmoke;
    text-transform: uppercase;
    display: flex;
    align-items: flex-end;
    height: 1.2em;
}

.center > .menu-label {
    color: rgb(22, 154, 165);
}

@media only screen and (max-width: 480px) {
    .menu-label {
        font-size: 0.7em;
        text-shadow: 2px 2px 1px rgba(22, 154, 165, 0.84);
    }
}

.menu-circle {
        /*background-color: #03a9f4;*/
        background-size: cover;
        background-position: center;
        height: auto;
        display: flex;
        /*border-radius: 50%;*/
        align-items: flex-end;
        justify-content: flex-end;
        filter: grayscale(1);
}
