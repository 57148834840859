body {
  margin: 0;
  padding: 0;
  font-family: 'Bellefair', sans-serif;
}


.menu-wrapper {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}

.menu-wrapper div:not(.menu-circle) {
    height: 40px;
    transition: max-height .3s ease-in-out;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-end;
            align-items: flex-end;

}

.menu-item {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    outline: none;
    height: 100%;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 3.2vh 0;
    max-width: calc(100vw / 6);
}

.menu-circle.center {
    /*height: 9;*/
    -webkit-filter: unset;
            filter: unset;
}

.menu-label {
    /*background-color: rgba(0,0,0,0.42);*/
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 700;
    text-align: right;
    font-size: 1.2em;
    color: whitesmoke;
    text-transform: uppercase;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    height: 1.2em;
}

.center > .menu-label {
    color: rgb(22, 154, 165);
}

@media only screen and (max-width: 480px) {
    .menu-label {
        font-size: 0.7em;
        text-shadow: 2px 2px 1px rgba(22, 154, 165, 0.84);
    }
}

.menu-circle {
        /*background-color: #03a9f4;*/
        background-size: cover;
        background-position: center;
        height: auto;
        display: -webkit-flex;
        display: flex;
        /*border-radius: 50%;*/
        -webkit-align-items: flex-end;
                align-items: flex-end;
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
        -webkit-filter: grayscale(1);
                filter: grayscale(1);
}

/*.glitch-text {*/
/*    !*font-family: 'Inconsolata', monospace!important;*!*/
/*    color: white;*/
/*    font-size: 100px;*/
/*    position: relative;*/
/*    width: 285px;*/
/*    margin: 0 auto;*/
/*}*/

/*@keyframes noise-anim {*/
/*    0% {*/
/*        clip: rect(4px, 9999px, 21px, 0);*/
/*    }*/
/*    5% {*/
/*        clip: rect(14px, 9999px, 19px, 0);*/
/*    }*/
/*    10% {*/
/*        clip: rect(6px, 9999px, 78px, 0);*/
/*    }*/
/*    15% {*/
/*        clip: rect(48px, 9999px, 56px, 0);*/
/*    }*/
/*    20% {*/
/*        clip: rect(5px, 9999px, 96px, 0);*/
/*    }*/
/*    25% {*/
/*        clip: rect(73px, 9999px, 100px, 0);*/
/*    }*/
/*    30% {*/
/*        clip: rect(73px, 9999px, 30px, 0);*/
/*    }*/
/*    35% {*/
/*        clip: rect(91px, 9999px, 48px, 0);*/
/*    }*/
/*    40% {*/
/*        clip: rect(76px, 9999px, 7px, 0);*/
/*    }*/
/*    45% {*/
/*        clip: rect(97px, 9999px, 61px, 0);*/
/*    }*/
/*    50% {*/
/*        clip: rect(90px, 9999px, 92px, 0);*/
/*    }*/
/*    55% {*/
/*        clip: rect(41px, 9999px, 94px, 0);*/
/*    }*/
/*    60% {*/
/*        clip: rect(66px, 9999px, 61px, 0);*/
/*    }*/
/*    65% {*/
/*        clip: rect(39px, 9999px, 100px, 0);*/
/*    }*/
/*    70% {*/
/*        clip: rect(73px, 9999px, 82px, 0);*/
/*    }*/
/*    75% {*/
/*        clip: rect(70px, 9999px, 52px, 0);*/
/*    }*/
/*    80% {*/
/*        clip: rect(97px, 9999px, 81px, 0);*/
/*    }*/
/*    85% {*/
/*        clip: rect(84px, 9999px, 36px, 0);*/
/*    }*/
/*    90% {*/
/*        clip: rect(24px, 9999px, 8px, 0);*/
/*    }*/
/*    95% {*/
/*        clip: rect(61px, 9999px, 4px, 0);*/
/*    }*/
/*    100% {*/
/*        clip: rect(61px, 9999px, 32px, 0);*/
/*    }*/
/*}*/
/*.glitch-text:after {*/
/*    content: attr(data-text);*/
/*    position: absolute;*/
/*    left: 2px;*/
/*    text-shadow: -1px 0 red;*/
/*    top: 0;*/
/*    color: white;*/
/*    overflow: hidden;*/
/*    clip: rect(0, 900px, 0, 0);*/
/*    animation: noise-anim 2s infinite linear alternate-reverse;*/
/*}*/

/*@keyframes noise-anim-2 {*/
/*    0% {*/
/*        clip: rect(18px, 9999px, 73px, 0);*/
/*    }*/
/*    5% {*/
/*        clip: rect(72px, 9999px, 48px, 0);*/
/*    }*/
/*    10% {*/
/*        clip: rect(70px, 9999px, 47px, 0);*/
/*    }*/
/*    15% {*/
/*        clip: rect(33px, 9999px, 75px, 0);*/
/*    }*/
/*    20% {*/
/*        clip: rect(31px, 9999px, 99px, 0);*/
/*    }*/
/*    25% {*/
/*        clip: rect(62px, 9999px, 19px, 0);*/
/*    }*/
/*    30% {*/
/*        clip: rect(2px, 9999px, 13px, 0);*/
/*    }*/
/*    35% {*/
/*        clip: rect(8px, 9999px, 38px, 0);*/
/*    }*/
/*    40% {*/
/*        clip: rect(80px, 9999px, 18px, 0);*/
/*    }*/
/*    45% {*/
/*        clip: rect(87px, 9999px, 96px, 0);*/
/*    }*/
/*    50% {*/
/*        clip: rect(23px, 9999px, 45px, 0);*/
/*    }*/
/*    55% {*/
/*        clip: rect(60px, 9999px, 15px, 0);*/
/*    }*/
/*    60% {*/
/*        clip: rect(89px, 9999px, 2px, 0);*/
/*    }*/
/*    65% {*/
/*        clip: rect(1px, 9999px, 77px, 0);*/
/*    }*/
/*    70% {*/
/*        clip: rect(99px, 9999px, 97px, 0);*/
/*    }*/
/*    75% {*/
/*        clip: rect(50px, 9999px, 92px, 0);*/
/*    }*/
/*    80% {*/
/*        clip: rect(69px, 9999px, 37px, 0);*/
/*    }*/
/*    85% {*/
/*        clip: rect(1px, 9999px, 74px, 0);*/
/*    }*/
/*    90% {*/
/*        clip: rect(4px, 9999px, 62px, 0);*/
/*    }*/
/*    95% {*/
/*        clip: rect(48px, 9999px, 7px, 0);*/
/*    }*/
/*    100% {*/
/*        clip: rect(47px, 9999px, 48px, 0);*/
/*    }*/
/*}*/
/*.glitch-text:before {*/
/*    content: attr(data-text);*/
/*    position: absolute;*/
/*    left: -2px;*/
/*    text-shadow: 1px 0 blue;*/
/*    top: 0;*/
/*    color: #03a9f4;*/
/*    overflow: hidden;*/
/*    clip: rect(0, 900px, 0, 0);*/
/*    animation: noise-anim-2 10s infinite linear alternate-reverse;*/
/*}*/

.glitch-text {
    color: white;
    font-size: 100px;
    position: relative;
    width: 400px;
    margin: 0 auto;
}

@-webkit-keyframes noise-anim {
    0% {
        clip: rect(58px, 9999px, 83px, 0);
    }
    5% {
        clip: rect(83px, 9999px, 67px, 0);
    }
    10% {
        clip: rect(79px, 9999px, 88px, 0);
    }
    15% {
        clip: rect(90px, 9999px, 35px, 0);
    }
    20% {
        clip: rect(97px, 9999px, 12px, 0);
    }
    25% {
        clip: rect(24px, 9999px, 44px, 0);
    }
    30% {
        clip: rect(57px, 9999px, 61px, 0);
    }
    35% {
        clip: rect(90px, 9999px, 69px, 0);
    }
    40% {
        clip: rect(49px, 9999px, 87px, 0);
    }
    45% {
        clip: rect(87px, 9999px, 50px, 0);
    }
    50% {
        clip: rect(50px, 9999px, 36px, 0);
    }
    55% {
        clip: rect(62px, 9999px, 42px, 0);
    }
    60% {
        clip: rect(68px, 9999px, 22px, 0);
    }
    65% {
        clip: rect(25px, 9999px, 89px, 0);
    }
    70% {
        clip: rect(61px, 9999px, 65px, 0);
    }
    75% {
        clip: rect(51px, 9999px, 90px, 0);
    }
    80% {
        clip: rect(12px, 9999px, 7px, 0);
    }
    85% {
        clip: rect(83px, 9999px, 89px, 0);
    }
    90% {
        clip: rect(40px, 9999px, 34px, 0);
    }
    95% {
        clip: rect(28px, 9999px, 66px, 0);
    }
    100% {
        clip: rect(3px, 9999px, 69px, 0);
    }
}

@keyframes noise-anim {
    0% {
        clip: rect(58px, 9999px, 83px, 0);
    }
    5% {
        clip: rect(83px, 9999px, 67px, 0);
    }
    10% {
        clip: rect(79px, 9999px, 88px, 0);
    }
    15% {
        clip: rect(90px, 9999px, 35px, 0);
    }
    20% {
        clip: rect(97px, 9999px, 12px, 0);
    }
    25% {
        clip: rect(24px, 9999px, 44px, 0);
    }
    30% {
        clip: rect(57px, 9999px, 61px, 0);
    }
    35% {
        clip: rect(90px, 9999px, 69px, 0);
    }
    40% {
        clip: rect(49px, 9999px, 87px, 0);
    }
    45% {
        clip: rect(87px, 9999px, 50px, 0);
    }
    50% {
        clip: rect(50px, 9999px, 36px, 0);
    }
    55% {
        clip: rect(62px, 9999px, 42px, 0);
    }
    60% {
        clip: rect(68px, 9999px, 22px, 0);
    }
    65% {
        clip: rect(25px, 9999px, 89px, 0);
    }
    70% {
        clip: rect(61px, 9999px, 65px, 0);
    }
    75% {
        clip: rect(51px, 9999px, 90px, 0);
    }
    80% {
        clip: rect(12px, 9999px, 7px, 0);
    }
    85% {
        clip: rect(83px, 9999px, 89px, 0);
    }
    90% {
        clip: rect(40px, 9999px, 34px, 0);
    }
    95% {
        clip: rect(28px, 9999px, 66px, 0);
    }
    100% {
        clip: rect(3px, 9999px, 69px, 0);
    }
}
.glitch-text:after {
    content: attr(data-text);
    position: absolute;
    left: 2px;
    text-shadow: -1px 0 red;
    top: 0;
    color: white;
    /*background: black;*/
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim 2s infinite linear alternate-reverse;
}

@-webkit-keyframes noise-anim-2 {
    0% {
        clip: rect(99px, 9999px, 15px, 0);
    }
    5% {
        clip: rect(49px, 9999px, 94px, 0);
    }
    10% {
        clip: rect(95px, 9999px, 70px, 0);
    }
    15% {
        clip: rect(7px, 9999px, 38px, 0);
    }
    20% {
        clip: rect(21px, 9999px, 55px, 0);
    }
    25% {
        clip: rect(61px, 9999px, 14px, 0);
    }
    30% {
        clip: rect(20px, 9999px, 100px, 0);
    }
    35% {
        clip: rect(90px, 9999px, 81px, 0);
    }
    40% {
        clip: rect(8px, 9999px, 28px, 0);
    }
    45% {
        clip: rect(56px, 9999px, 47px, 0);
    }
    50% {
        clip: rect(92px, 9999px, 55px, 0);
    }
    55% {
        clip: rect(56px, 9999px, 51px, 0);
    }
    60% {
        clip: rect(5px, 9999px, 56px, 0);
    }
    65% {
        clip: rect(78px, 9999px, 5px, 0);
    }
    70% {
        clip: rect(40px, 9999px, 97px, 0);
    }
    75% {
        clip: rect(60px, 9999px, 15px, 0);
    }
    80% {
        clip: rect(93px, 9999px, 72px, 0);
    }
    85% {
        clip: rect(50px, 9999px, 65px, 0);
    }
    90% {
        clip: rect(25px, 9999px, 9px, 0);
    }
    95% {
        clip: rect(88px, 9999px, 96px, 0);
    }
    100% {
        clip: rect(35px, 9999px, 47px, 0);
    }
}

@keyframes noise-anim-2 {
    0% {
        clip: rect(99px, 9999px, 15px, 0);
    }
    5% {
        clip: rect(49px, 9999px, 94px, 0);
    }
    10% {
        clip: rect(95px, 9999px, 70px, 0);
    }
    15% {
        clip: rect(7px, 9999px, 38px, 0);
    }
    20% {
        clip: rect(21px, 9999px, 55px, 0);
    }
    25% {
        clip: rect(61px, 9999px, 14px, 0);
    }
    30% {
        clip: rect(20px, 9999px, 100px, 0);
    }
    35% {
        clip: rect(90px, 9999px, 81px, 0);
    }
    40% {
        clip: rect(8px, 9999px, 28px, 0);
    }
    45% {
        clip: rect(56px, 9999px, 47px, 0);
    }
    50% {
        clip: rect(92px, 9999px, 55px, 0);
    }
    55% {
        clip: rect(56px, 9999px, 51px, 0);
    }
    60% {
        clip: rect(5px, 9999px, 56px, 0);
    }
    65% {
        clip: rect(78px, 9999px, 5px, 0);
    }
    70% {
        clip: rect(40px, 9999px, 97px, 0);
    }
    75% {
        clip: rect(60px, 9999px, 15px, 0);
    }
    80% {
        clip: rect(93px, 9999px, 72px, 0);
    }
    85% {
        clip: rect(50px, 9999px, 65px, 0);
    }
    90% {
        clip: rect(25px, 9999px, 9px, 0);
    }
    95% {
        clip: rect(88px, 9999px, 96px, 0);
    }
    100% {
        clip: rect(35px, 9999px, 47px, 0);
    }
}
.glitch-text:before {
    content: attr(data-text);
    position: absolute;
    left: -2px;
    text-shadow: 1px 0 blue;
    top: 0;
    color: white;
    /*background: black;*/
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim-2 3s infinite linear alternate-reverse;
}




.top-banner {
    background-image: url(/static/media/1HOMEBIANCAOFFICIAL_HOME.9248bc0f.jpg);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    background-size: cover;
    background-position: bottom;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 125vh;
    padding-top: 25vh;
}

.second-banner-container {
    margin-top: -25vh;
    background-image: url(/static/media/2ABOUTPANEL_BIANCAOFFICIAL.45078807.png);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    background-size: cover;
    background-position: top;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    height: 100vh;
}

.third-banner-container {
    background: black;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 100vh;
}

.fourth-banner-container {
    background-image: url(/static/media/DATESPANEL_BIANCAOFFICIAL.ef1375ff.png);
    background-size: cover;
    background-position: bottom;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
    height: 125vh;
    margin-top: -24vh;
    position: relative;
    z-index: 3;
}
.fifth-banner-container {
    background-image: url(/static/media/BIANCAOFFICIAL_SIGNUP.36f54aba.jpg);
    background-size: cover;
    background-position: bottom;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 125vh;
}
.top-signature {
    background-image: url(/static/media/BIANCA_SIGNATURE_COLOURS.c9351334.gif);
    display: -webkit-flex;
    display: flex;
    height: 25vh;
    width: 50vw;
    background-size: contain;
    background-repeat: no-repeat;
}
.third-banner-signature {
    background-image: url(/static/media/BIANCA_SIGNATURE_COLOURS.c9351334.gif);
    display: -webkit-flex;
    display: flex;
    height: 25vh;
    width: 75vw;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    z-index: 2;
}
.banner-2 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.banner-2-text {
    width: 40vw;
    font-size: 2rem;
    line-height: 2.2rem;
    color: #da123b;
    font-family: 'Inconsolata', monospace;
    margin-right: 5vw;
}

.banner-2-text h2 {
    font-family: 'Inconsolata', monospace;
    text-align: center;
}

.banner-3 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    position: absolute;
    z-index: 1;
}
.banner-4-text {
    width: 40vw;
    font-size: 2.5rem;
    line-height: 2rem;
    color: #000000;
    text-transform: uppercase;
    font-family: 'Oswald', monospace;
    font-weight: 800;
    margin-left: 120px;
    min-width: 430px;
    max-width: 475px;
}

.banner-4-text p {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.banner-4-text p:first-child {
    margin-bottom: 60px;
}

.banner-4-text p:not(:first-child) {
    margin: 10px 0;
}

.banner-2 .parallax-banner-layer-1 {
    width: 30vw!important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    background-position: 0 36vh !important;
}

.banner-2 .parallax-banner-layer-0 {
    width: 45vw!important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    background-position: -5vw 38vh !important;
}

.sign-up-container {
    min-width: 22vw;
    height: 40vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    text-transform: uppercase;
    font-family: 'Oswald', monospace;
    color: #ffffff;
    font-weight: 800;
    text-align: center;
}
.sign-up-text {
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 700;
    font-size: 35px;
    margin-bottom: 30px;
}
.sign-up-form {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}
.MuiOutlinedInput-root {
    background-color: rgba(105, 101, 103, 0.55);
}
.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: none;
    border-radius: 0;
}
.MuiFormLabel-root {
    font-family: 'Oswald', monospace!important;
    font-weight: 800;
    text-align: center;
}
.MuiOutlinedInput-root {
    margin-bottom: 15px;
}
.MuiFilledInput-root {
    margin-bottom: 15px;
}
.MuiInputBase-root {
    max-width: 500px;
    width: 30vw;
}

.submit-btn {
    font-family: 'Oswald', monospace!important;
    font-weight: 800!important;
    font-size: 25px!important;
}

.icon-row {
    display: -webkit-flex;
    display: flex;
    position: fixed;
    bottom: 0;
    z-index: 50000;
    width: 100vw;
    -webkit-justify-content: center;
            justify-content: center;
}
.icon-row .MuiIconButton-root {
    color: #FFBC81!important;
}

button:focus {
    background-color: transparent;
}

/* MOBILE STYLES*/

@media only screen and (-webkit-min-device-pixel-ratio: 1.3),
    only screen and (-webkit-min-device-pixel-ratio: 1.25),
    only screen and (min-resolution: 120dpi) {
    .top-banner {
        background-image: url(/static/media/HOMESPLASH_BIANCA_BACKGROUND.06a54ae2.png);
        height: 110vh;
        width: 100vw;
        background-repeat: no-repeat;
        background-position: top;
    }
    .top-signature {
        height: 25vh;
        width: 85vw;
    }
    .second-banner-container {
        background-image: url(/static/media/ABOUT_BIANCA_BACKGROUND.00828dbb.png);
        margin-top: -10vh;
        height: 110vh;
        width: 100vw;
        background-repeat: no-repeat;
        background-position: top;
    }
    .banner-2 {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: start;
                align-items: start;
        -webkit-justify-content: center;
                justify-content: center;
        overflow: visible !important;
    }
    .banner-2-text {
        width: 80vw;
        font-size: 1.1rem;
        line-height: 1.2rem;
        color: #ffffff;
        margin-top: 5vh;
        min-width: unset;
    }
    .banner-2 .parallax-banner-layer-1 {
        height: 115vh;
        width: 85vw!important;
        background-position: -4px 57vh !important;
        /*left: 12%!important;*/
    }
    .banner-2 .parallax-banner-layer-0 {
        width: 100vw!important;
        height: 200vh;
        background-position: -10vw 63vh !important;
        /*left: 12%!important;*/
    }

    .third-banner-container {
        width: 100vw;
        height: 150vh;
    }

    .third-banner-signature {
        height: 24vh;
        width: 85vw;
    }

    .fourth-banner-container {
        background-image: url(/static/media/DATES_BIANCA_BACKGROUND.84034d76.png);
        height: 110vh;
        width: 100vw;
        background-repeat: no-repeat;
        background-position: top;
        -webkit-align-items: start;
                align-items: start;

    }
    .banner-4-text {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
        width: 100vw;
        margin-left: 0;
        font-size: 1.6rem;
        line-height: 1.9rem;
        color: #ffffff;
        text-align: center;
        min-width: unset;
        margin-top: 40vh;
        /*change back to margin-top: 10vh when dates are in*/
    }
    .banner-4-text p {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        width: 100%;
    }

    .banner-4-text p:first-child {
        margin-bottom: 10px;
    }

    .fifth-banner-container {
        background-image: url(/static/media/SIGNUP_BIANCA_BACKGROUND.b793b665.png);
        height: 110vh;
        width: 100vw;
        background-repeat: no-repeat;
        background-position: top;
    }
    .sign-up-container {
        min-width: 50vw;
        margin-top: 35vh;
        width: 65vw;
    }
    .sign-up-text {
        font-size: 28px;
        margin-bottom: 25px;
    }

    .MuiInputBase-root {
        width: 60vw;
    }
    /*.banner-2 .parallax-banner-layer-1 {*/
    /*    width: 90vw!important;*/
    /*    background-repeat: no-repeat !important;*/
    /*    background-size: contain !important;*/
    /*    background-position: 0 36vh !important;*/
    /*}*/

    /*.banner-2 .parallax-banner-layer-0 {*/
    /*    width: 45vw!important;*/
    /*    background-repeat: no-repeat !important;*/
    /*    background-size: contain !important;*/
    /*    background-position: -5vw 38vh !important;*/
    /*}*/



}





