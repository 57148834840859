/*.glitch-text {*/
/*    !*font-family: 'Inconsolata', monospace!important;*!*/
/*    color: white;*/
/*    font-size: 100px;*/
/*    position: relative;*/
/*    width: 285px;*/
/*    margin: 0 auto;*/
/*}*/

/*@keyframes noise-anim {*/
/*    0% {*/
/*        clip: rect(4px, 9999px, 21px, 0);*/
/*    }*/
/*    5% {*/
/*        clip: rect(14px, 9999px, 19px, 0);*/
/*    }*/
/*    10% {*/
/*        clip: rect(6px, 9999px, 78px, 0);*/
/*    }*/
/*    15% {*/
/*        clip: rect(48px, 9999px, 56px, 0);*/
/*    }*/
/*    20% {*/
/*        clip: rect(5px, 9999px, 96px, 0);*/
/*    }*/
/*    25% {*/
/*        clip: rect(73px, 9999px, 100px, 0);*/
/*    }*/
/*    30% {*/
/*        clip: rect(73px, 9999px, 30px, 0);*/
/*    }*/
/*    35% {*/
/*        clip: rect(91px, 9999px, 48px, 0);*/
/*    }*/
/*    40% {*/
/*        clip: rect(76px, 9999px, 7px, 0);*/
/*    }*/
/*    45% {*/
/*        clip: rect(97px, 9999px, 61px, 0);*/
/*    }*/
/*    50% {*/
/*        clip: rect(90px, 9999px, 92px, 0);*/
/*    }*/
/*    55% {*/
/*        clip: rect(41px, 9999px, 94px, 0);*/
/*    }*/
/*    60% {*/
/*        clip: rect(66px, 9999px, 61px, 0);*/
/*    }*/
/*    65% {*/
/*        clip: rect(39px, 9999px, 100px, 0);*/
/*    }*/
/*    70% {*/
/*        clip: rect(73px, 9999px, 82px, 0);*/
/*    }*/
/*    75% {*/
/*        clip: rect(70px, 9999px, 52px, 0);*/
/*    }*/
/*    80% {*/
/*        clip: rect(97px, 9999px, 81px, 0);*/
/*    }*/
/*    85% {*/
/*        clip: rect(84px, 9999px, 36px, 0);*/
/*    }*/
/*    90% {*/
/*        clip: rect(24px, 9999px, 8px, 0);*/
/*    }*/
/*    95% {*/
/*        clip: rect(61px, 9999px, 4px, 0);*/
/*    }*/
/*    100% {*/
/*        clip: rect(61px, 9999px, 32px, 0);*/
/*    }*/
/*}*/
/*.glitch-text:after {*/
/*    content: attr(data-text);*/
/*    position: absolute;*/
/*    left: 2px;*/
/*    text-shadow: -1px 0 red;*/
/*    top: 0;*/
/*    color: white;*/
/*    overflow: hidden;*/
/*    clip: rect(0, 900px, 0, 0);*/
/*    animation: noise-anim 2s infinite linear alternate-reverse;*/
/*}*/

/*@keyframes noise-anim-2 {*/
/*    0% {*/
/*        clip: rect(18px, 9999px, 73px, 0);*/
/*    }*/
/*    5% {*/
/*        clip: rect(72px, 9999px, 48px, 0);*/
/*    }*/
/*    10% {*/
/*        clip: rect(70px, 9999px, 47px, 0);*/
/*    }*/
/*    15% {*/
/*        clip: rect(33px, 9999px, 75px, 0);*/
/*    }*/
/*    20% {*/
/*        clip: rect(31px, 9999px, 99px, 0);*/
/*    }*/
/*    25% {*/
/*        clip: rect(62px, 9999px, 19px, 0);*/
/*    }*/
/*    30% {*/
/*        clip: rect(2px, 9999px, 13px, 0);*/
/*    }*/
/*    35% {*/
/*        clip: rect(8px, 9999px, 38px, 0);*/
/*    }*/
/*    40% {*/
/*        clip: rect(80px, 9999px, 18px, 0);*/
/*    }*/
/*    45% {*/
/*        clip: rect(87px, 9999px, 96px, 0);*/
/*    }*/
/*    50% {*/
/*        clip: rect(23px, 9999px, 45px, 0);*/
/*    }*/
/*    55% {*/
/*        clip: rect(60px, 9999px, 15px, 0);*/
/*    }*/
/*    60% {*/
/*        clip: rect(89px, 9999px, 2px, 0);*/
/*    }*/
/*    65% {*/
/*        clip: rect(1px, 9999px, 77px, 0);*/
/*    }*/
/*    70% {*/
/*        clip: rect(99px, 9999px, 97px, 0);*/
/*    }*/
/*    75% {*/
/*        clip: rect(50px, 9999px, 92px, 0);*/
/*    }*/
/*    80% {*/
/*        clip: rect(69px, 9999px, 37px, 0);*/
/*    }*/
/*    85% {*/
/*        clip: rect(1px, 9999px, 74px, 0);*/
/*    }*/
/*    90% {*/
/*        clip: rect(4px, 9999px, 62px, 0);*/
/*    }*/
/*    95% {*/
/*        clip: rect(48px, 9999px, 7px, 0);*/
/*    }*/
/*    100% {*/
/*        clip: rect(47px, 9999px, 48px, 0);*/
/*    }*/
/*}*/
/*.glitch-text:before {*/
/*    content: attr(data-text);*/
/*    position: absolute;*/
/*    left: -2px;*/
/*    text-shadow: 1px 0 blue;*/
/*    top: 0;*/
/*    color: #03a9f4;*/
/*    overflow: hidden;*/
/*    clip: rect(0, 900px, 0, 0);*/
/*    animation: noise-anim-2 10s infinite linear alternate-reverse;*/
/*}*/

.glitch-text {
    color: white;
    font-size: 100px;
    position: relative;
    width: 400px;
    margin: 0 auto;
}

@keyframes noise-anim {
    0% {
        clip: rect(58px, 9999px, 83px, 0);
    }
    5% {
        clip: rect(83px, 9999px, 67px, 0);
    }
    10% {
        clip: rect(79px, 9999px, 88px, 0);
    }
    15% {
        clip: rect(90px, 9999px, 35px, 0);
    }
    20% {
        clip: rect(97px, 9999px, 12px, 0);
    }
    25% {
        clip: rect(24px, 9999px, 44px, 0);
    }
    30% {
        clip: rect(57px, 9999px, 61px, 0);
    }
    35% {
        clip: rect(90px, 9999px, 69px, 0);
    }
    40% {
        clip: rect(49px, 9999px, 87px, 0);
    }
    45% {
        clip: rect(87px, 9999px, 50px, 0);
    }
    50% {
        clip: rect(50px, 9999px, 36px, 0);
    }
    55% {
        clip: rect(62px, 9999px, 42px, 0);
    }
    60% {
        clip: rect(68px, 9999px, 22px, 0);
    }
    65% {
        clip: rect(25px, 9999px, 89px, 0);
    }
    70% {
        clip: rect(61px, 9999px, 65px, 0);
    }
    75% {
        clip: rect(51px, 9999px, 90px, 0);
    }
    80% {
        clip: rect(12px, 9999px, 7px, 0);
    }
    85% {
        clip: rect(83px, 9999px, 89px, 0);
    }
    90% {
        clip: rect(40px, 9999px, 34px, 0);
    }
    95% {
        clip: rect(28px, 9999px, 66px, 0);
    }
    100% {
        clip: rect(3px, 9999px, 69px, 0);
    }
}
.glitch-text:after {
    content: attr(data-text);
    position: absolute;
    left: 2px;
    text-shadow: -1px 0 red;
    top: 0;
    color: white;
    /*background: black;*/
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim 2s infinite linear alternate-reverse;
}

@keyframes noise-anim-2 {
    0% {
        clip: rect(99px, 9999px, 15px, 0);
    }
    5% {
        clip: rect(49px, 9999px, 94px, 0);
    }
    10% {
        clip: rect(95px, 9999px, 70px, 0);
    }
    15% {
        clip: rect(7px, 9999px, 38px, 0);
    }
    20% {
        clip: rect(21px, 9999px, 55px, 0);
    }
    25% {
        clip: rect(61px, 9999px, 14px, 0);
    }
    30% {
        clip: rect(20px, 9999px, 100px, 0);
    }
    35% {
        clip: rect(90px, 9999px, 81px, 0);
    }
    40% {
        clip: rect(8px, 9999px, 28px, 0);
    }
    45% {
        clip: rect(56px, 9999px, 47px, 0);
    }
    50% {
        clip: rect(92px, 9999px, 55px, 0);
    }
    55% {
        clip: rect(56px, 9999px, 51px, 0);
    }
    60% {
        clip: rect(5px, 9999px, 56px, 0);
    }
    65% {
        clip: rect(78px, 9999px, 5px, 0);
    }
    70% {
        clip: rect(40px, 9999px, 97px, 0);
    }
    75% {
        clip: rect(60px, 9999px, 15px, 0);
    }
    80% {
        clip: rect(93px, 9999px, 72px, 0);
    }
    85% {
        clip: rect(50px, 9999px, 65px, 0);
    }
    90% {
        clip: rect(25px, 9999px, 9px, 0);
    }
    95% {
        clip: rect(88px, 9999px, 96px, 0);
    }
    100% {
        clip: rect(35px, 9999px, 47px, 0);
    }
}
.glitch-text:before {
    content: attr(data-text);
    position: absolute;
    left: -2px;
    text-shadow: 1px 0 blue;
    top: 0;
    color: white;
    /*background: black;*/
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim-2 3s infinite linear alternate-reverse;
}

