

.top-banner {
    background-image: url('../../../assets/images/updated-page/1HOMEBIANCAOFFICIAL_HOME.jpg');
    display: flex;
    flex-grow: 1;
    background-size: cover;
    background-position: bottom;
    justify-content: center;
    align-items: center;
    height: 125vh;
    padding-top: 25vh;
}

.second-banner-container {
    margin-top: -25vh;
    background-image: url('../../../assets/images/updated-page/2ABOUTPANEL_BIANCAOFFICIAL.png');
    display: flex;
    flex-grow: 1;
    background-size: cover;
    background-position: top;
    justify-content: flex-end;
    height: 100vh;
}

.third-banner-container {
    background: black;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.fourth-banner-container {
    background-image: url('../../../assets/images/updated-page/DATESPANEL_BIANCAOFFICIAL.png');
    background-size: cover;
    background-position: bottom;
    display: flex;
    flex-grow: 1;
    justify-content: start;
    align-items: center;
    height: 125vh;
    margin-top: -24vh;
    position: relative;
    z-index: 3;
}
.fifth-banner-container {
    background-image: url('../../../assets/images/updated-page/BIANCAOFFICIAL_SIGNUP.jpg');
    background-size: cover;
    background-position: bottom;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    height: 125vh;
}
.top-signature {
    background-image: url('../../../assets/images/updated-page/BIANCA_SIGNATURE_COLOURS.gif');
    display: flex;
    height: 25vh;
    width: 50vw;
    background-size: contain;
    background-repeat: no-repeat;
}
.third-banner-signature {
    background-image: url('../../../assets/images/updated-page/BIANCA_SIGNATURE_COLOURS.gif');
    display: flex;
    height: 25vh;
    width: 75vw;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    z-index: 2;
}
.banner-2 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.banner-2-text {
    width: 40vw;
    font-size: 2rem;
    line-height: 2.2rem;
    color: #da123b;
    font-family: 'Inconsolata', monospace;
    margin-right: 5vw;
}

.banner-2-text h2 {
    font-family: 'Inconsolata', monospace;
    text-align: center;
}

.banner-3 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    z-index: 1;
}
.banner-4-text {
    width: 40vw;
    font-size: 2.5rem;
    line-height: 2rem;
    color: #000000;
    text-transform: uppercase;
    font-family: 'Oswald', monospace;
    font-weight: 800;
    margin-left: 120px;
    min-width: 430px;
    max-width: 475px;
}

.banner-4-text p {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.banner-4-text p:first-child {
    margin-bottom: 60px;
}

.banner-4-text p:not(:first-child) {
    margin: 10px 0;
}

.banner-2 .parallax-banner-layer-1 {
    width: 30vw!important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    background-position: 0 36vh !important;
}

.banner-2 .parallax-banner-layer-0 {
    width: 45vw!important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    background-position: -5vw 38vh !important;
}

.sign-up-container {
    min-width: 22vw;
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-transform: uppercase;
    font-family: 'Oswald', monospace;
    color: #ffffff;
    font-weight: 800;
    text-align: center;
}
.sign-up-text {
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 700;
    font-size: 35px;
    margin-bottom: 30px;
}
.sign-up-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.MuiOutlinedInput-root {
    background-color: rgba(105, 101, 103, 0.55);
}
.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: none;
    border-radius: 0;
}
.MuiFormLabel-root {
    font-family: 'Oswald', monospace!important;
    font-weight: 800;
    text-align: center;
}
.MuiOutlinedInput-root {
    margin-bottom: 15px;
}
.MuiFilledInput-root {
    margin-bottom: 15px;
}
.MuiInputBase-root {
    max-width: 500px;
    width: 30vw;
}

.submit-btn {
    font-family: 'Oswald', monospace!important;
    font-weight: 800!important;
    font-size: 25px!important;
}

.icon-row {
    display: flex;
    position: fixed;
    bottom: 0;
    z-index: 50000;
    width: 100vw;
    justify-content: center;
}
.icon-row .MuiIconButton-root {
    color: #FFBC81!important;
}

button:focus {
    background-color: transparent;
}

/* MOBILE STYLES*/

@media only screen and (-webkit-min-device-pixel-ratio: 1.3),
    only screen and (-o-min-device-pixel-ratio: 13/10),
    only screen and (min-resolution: 120dpi) {
    .top-banner {
        background-image: url('../../../assets/images/mobile/HOMESPLASH_BIANCA_BACKGROUND.png');
        height: 110vh;
        width: 100vw;
        background-repeat: no-repeat;
        background-position: top;
    }
    .top-signature {
        height: 25vh;
        width: 85vw;
    }
    .second-banner-container {
        background-image: url('../../../assets/images/mobile/ABOUT_BIANCA_BACKGROUND.png');
        margin-top: -10vh;
        height: 110vh;
        width: 100vw;
        background-repeat: no-repeat;
        background-position: top;
    }
    .banner-2 {
        display: flex;
        align-items: start;
        justify-content: center;
        overflow: visible !important;
    }
    .banner-2-text {
        width: 80vw;
        font-size: 1.1rem;
        line-height: 1.2rem;
        color: #ffffff;
        margin-top: 5vh;
        min-width: unset;
    }
    .banner-2 .parallax-banner-layer-1 {
        height: 115vh;
        width: 85vw!important;
        background-position: -4px 57vh !important;
        /*left: 12%!important;*/
    }
    .banner-2 .parallax-banner-layer-0 {
        width: 100vw!important;
        height: 200vh;
        background-position: -10vw 63vh !important;
        /*left: 12%!important;*/
    }

    .third-banner-container {
        width: 100vw;
        height: 150vh;
    }

    .third-banner-signature {
        height: 24vh;
        width: 85vw;
    }

    .fourth-banner-container {
        background-image: url('../../../assets/images/mobile/DATES_BIANCA_BACKGROUND.png');
        height: 110vh;
        width: 100vw;
        background-repeat: no-repeat;
        background-position: top;
        align-items: start;

    }
    .banner-4-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100vw;
        margin-left: 0;
        font-size: 1.6rem;
        line-height: 1.9rem;
        color: #ffffff;
        text-align: center;
        min-width: unset;
        margin-top: 40vh;
        /*change back to margin-top: 10vh when dates are in*/
    }
    .banner-4-text p {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .banner-4-text p:first-child {
        margin-bottom: 10px;
    }

    .fifth-banner-container {
        background-image: url('../../../assets/images/mobile/SIGNUP_BIANCA_BACKGROUND.png');
        height: 110vh;
        width: 100vw;
        background-repeat: no-repeat;
        background-position: top;
    }
    .sign-up-container {
        min-width: 50vw;
        margin-top: 35vh;
        width: 65vw;
    }
    .sign-up-text {
        font-size: 28px;
        margin-bottom: 25px;
    }

    .MuiInputBase-root {
        width: 60vw;
    }
    /*.banner-2 .parallax-banner-layer-1 {*/
    /*    width: 90vw!important;*/
    /*    background-repeat: no-repeat !important;*/
    /*    background-size: contain !important;*/
    /*    background-position: 0 36vh !important;*/
    /*}*/

    /*.banner-2 .parallax-banner-layer-0 {*/
    /*    width: 45vw!important;*/
    /*    background-repeat: no-repeat !important;*/
    /*    background-size: contain !important;*/
    /*    background-position: -5vw 38vh !important;*/
    /*}*/



}




